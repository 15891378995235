import { LitElement, html, css, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import connect from 'wc-context-redux/connect';
import '@sdblearning/sdb-learning-frontend';
import './player/sdb-embedded-composition-player-header.js';
import './player/sdb-embedded-composition-player.js';
import './elearning/sdb-embedded-elearning-dialog.js';

const mapStateToProps = state => ({
    userName: state.oidc.user.profile.name,
    user: state.oidc.user,
    compositionTitle: (state.composition.data || {}).title,
});

const mapDispatchToProps = dispatch => ({
});

@customElement('sdb-player-demo-app')
class SdbCompositionDemoApp extends connect(mapStateToProps, mapDispatchToProps)(LitElement) {

    @property({ type: String })
    accessor targetId;

    @property({ type: String })
    accessor sessionId;

    @property({ type: String })
    accessor type;

    @property({ type: Boolean })
    accessor preview;

    @property({ type: String })
    accessor idString;

    @state()
    accessor compositionTitle = '';

    @state()
    accessor singleItem = true;

    @state()
    accessor mainViewEnabled = false;

    static get styles() {
        return css`
            :host {
                --btn-primary: #f49d10;
                --button-primary-text: #000;
                --progress-primary: #f49d10;
                --progress-100: green;
            }

            .sdb-header {
                background: #fff;
                height: 70px;
                border-bottom: 1px solid #ddd;
                padding-left: 10%;
                padding-right: 10%;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            .title {
                font-size: 20px;
                display: flex;
                align-items: center;
                justify-content: start;
            }

            .chip {
                background: orange;
                padding: 3px 15px 5px 15px;
                margin-left: 20px;
                border-radius: 8px;
                font-size: 16px;
                color: #fff;
                position: relative;
                top: -2px;
            }

            .hero {
                margin-top: 20px;
                margin-left: 50px;
                margin-right: 50px;
                background: #1E968B;
            }

            .content-card {
                width: 30%;
                border: 1px solid #888;
                margin-left: 50px;
                padding: 20px;
            }

            .button-bar {
                background: #eee;
                height: 70px;
                border-bottom: 1px solid #ddd;
                padding-left: 10%;
                padding-right: 10%;
                display: flex;
                align-items: center;
            }
        `;
    }

    render() {
        if (this.error)
            return html`
                <style>
                    div {
                        margin: 20px;
                    }
                </style>
                <div>${this.error}</div>
            `;

        if (this.isLoading)
            return html`<div class="spinner"></div>`;

        return html`
            <div class="sdb-header">
                <div class="title">
                    <div>${this.compositionTitle}</div>
                    <div><span class="chip">DEMO</span></div>
                </div>
                <div>${this.userName}</div>
            </div>
            <div class="hero">
                <sdb-embedded-composition-player-header
                    .settings=${{ portalIdentifier: 'TCG', env: window.ENVIRONMENT }}
                    .user=${this.user}
                    targetId=${this.targetId}
                    @start=${(e) => this._onStart(e)}
                    @reset=${(e) => this._onReset(e)}
                    light>
                </sdb-embedded-composition-player-header>
            </div>
            <div class="content-card">
                <sdb-embedded-composition-player-header
                    .settings=${{ portalIdentifier: 'TCG', env: window.ENVIRONMENT }}
                    .user=${this.user}
                    targetId=${this.targetId}
                    @start=${(e) => this._onStart(e)}
                    @reset=${(e) => this._onReset(e)}
                    small
                    compressed>
                </sdb-embedded-composition-player-header>
            </div>
            <sdb-layout-main>
                ${this.mainViewEnabled ? html`
                    <sdb-embedded-composition-player
                        .settings=${{ portalIdentifier: 'TCG', env: window.ENVIRONMENT }}
                        .user=${this.user}
                        targetId=${this.targetId}
                        sessionId=${this.sessionId}
                        .type=${this.type}
                        preview>
                    </sdb-embedded-composition-player>
                ` : nothing}
            </sdb-layout-main>

            <sdb-overlay-element>
                <sdb-embedded-elearning-dialog
                    .settings=${{ portalIdentifier: 'TCG', env: window.ENVIRONMENT }}
                    .user=${this.user}
                    transparent>
                </sdb-embedded-elearning-dialog>
            </sdb-overlay-eleemnt>
        `;
    }

    _onStart(e) {
        this.mainViewEnabled = !e.detail.isSingleItem;
    }

    _onReset(e) {
        this.mainViewEnabled = !e.detail.isSingleItem;
    }
}
