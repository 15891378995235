export default {
    'en' : {
        'btnStart': 'start',
        'btnIncomplete': 'continue',
        'btnCompleted': 'open',
        'btnReset': 'do again',
        'btnResetAdmin': 'reset progress',
        'btnCancel': 'cancel',
        'dlgResetHeader': 'Do again?',
        'dlgResetHeaderAdmin': 'Reset',
        'dlgResetBody': 'Are you sure you want to do e-learning "${this.catalogName}" again?<br>All your current progress will then be lost. It does not affect existing reports.',
        'dlgResetBodyAdmin': 'Are you sure you want to reset this progress?<br>The current progress will then be lost. It does not affect existing reports.',
        'dlgResetBodyLastWarning': 'Do "${this.catalogName}" again?',
        'dlgResetBodyLastWarningAdmin': 'Permanently reset "${this.catalogName}" progress?',
        'scormPackageNotEnabled': 'This content is not yet available',
        'titleMovie': 'video',
        'titleAnimation': 'animation',
        'titleAudio': 'audio',
        'titleInfographic': 'infographic',
        'titleExercise': 'exercise',
        'titleMicrocourse': 'microcourse',
        'titleText': 'text',
        'titleTest': 'test',
        'titlePretest': 'assessment',
        'titleComplete': 'e-learning',
        'tabContent': 'Content',
        'tabInfo': 'Info',
        'newWindowMessage': 'This course will be launched in a new window. Please click the button to open the window.',
        'newWindowOpenedMessage': 'The course has been automatically opened in a new window.',
        'startInNewWIndow': 'Click here to start the course',
        'required': 'required',
        'status': 'Status',
        'progress_started': 'Not started',
        'progress_incomplete': 'Incomplete',
        'progress_completed': 'Completed',
        'progress_failed': 'failed',
        'progress_completed': 'passed',
        'mandatoryItems_marked': 'Mandatory items',
        'mandatoryItems_noSettings': 'Items completed',
        'mandatoryItems_all': 'All items completed',
        'mandatoryItems_any': 'At least 1 item',
        'mandatoryItems_min': 'At least ${this.num} items',
        'mandatoryItems_durationOnly': 'Items completed (not mandatory)',
        'mandatoryTime': 'Mandatory duration (minutes)',
        'minute': 'minute',
        'minutes': 'minutes',
        'information': 'Notice',
        'singleItemMessage': 'This e-learning will be launched in a dialog.',
    },
    'nl': {
        'btnStart': 'starten',
        'btnIncomplete': 'doorgaan',
        'btnCompleted': 'tonen',
        'btnReset': 'opnieuw maken',
        'btnResetAdmin': 'reset voortgang',
        'btnCancel': 'annuleer',
        'dlgResetHeader': 'Opnieuw maken?',
        'dlgResetHeaderAdmin': 'Voortgang resetten?',
        'dlgResetBody': 'Weet je zeker dat je e-learning "${this.catalogName}" opnieuw wilt maken?<br>Al jouw huidige voortgang gaat dan verloren. Het heeft geen invloed op bestaande rapportages.',
        'dlgResetBodyAdmin': 'Weet je zeker dat je deze voortgang wilt resetten?<br>De huidige voortgang gaat dan verloren. Het heeft geen invloed op bestaande rapportages.',
        'dlgResetBodyLastWarning': '"${this.catalogName}" opnieuw maken?.',
        'dlgResetBodyLastWarningAdmin': 'Voortgang van "${this.catalogName}" definitief resetten?.',
        'scormPackageNotEnabled': 'Deze e-learning is nog niet beschikbaar',
        'titleMovie': 'video',
        'titleAudio': 'audio',
        'titleAnimation': 'animatie',
        'titleInfographic': 'infographic',
        'titleExercise': 'oefenen',
        'titleMicrocourse': 'microcursus',
        'titleText': 'tekst',
        'titleTest': 'toets',
        'titlePretest': 'test jezelf',
        'titleComplete': 'e-learning',
        'tabContent': 'Inhoud',
        'tabInfo': 'Informatie',
        'newWindowMessage': 'Deze e-learning zal worden gestart in een nieuw scherm. Klik op onderstaande knop om de e-learning te starten.',
        'newWindowOpenedMessage': 'De e-learning is automatisch geopend in een nieuw scherm.',
        'startInNewWIndow': 'Klik hier om de e-learning te openen',
        'required': 'verplicht',
        'status': 'Status',
        'progress_started': 'Nog niet gestart', // i know
        'progress_incomplete': 'Nog bezig',
        'progress_completed': 'Afgerond',
        'progress_failed': 'Niet voldaan',
        'progress_completed': 'Voldaan',
        'mandatoryItems_marked': 'Verplichte onderdelen',
        'mandatoryItems_noSettings': 'Onderdelen afgerond',
        'mandatoryItems_all': 'Alle onderdelen afgerond',
        'mandatoryItems_any': 'Tenminste 1 onderdeel',
        'mandatoryItems_min': 'Tenminste ${this.num} onderdelen',
        'mandatoryItems_durationOnly': 'Onderdelen afgerond (niet verplicht)',
        'mandatoryTime': 'Verplichte tijdsduur (min)',
        'minute': 'minuut',
        'minutes': 'minuten',
        'information': 'Let op',
        'singleItemMessage': 'Deze e-learning wordt direct geopend in een dialoog.'
    }
}